@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.mainhome {
  padding-top: 120px;
  background-image: url("../../Assets/Backgroiundimg1.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.container {
  padding-top: 50px;
}

/* .row {
  width: 100%;
} */

.logo-text {
  font-size: 3rem;
  color: black;
}

.text-gradient {
  font-size: 1.25rem;
  color: black;
  margin-top: 0px;
}

.titletextOUTERHome {
  position: relative;
  font-weight: 700; /* This makes the text bold */
  font-family: "Times New Roman", Times, serif;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 70px;
  margin-top: 70px !important;
  font-size: 1.5rem; /* Increased the font size from 15px to 2.5rem */
}

.btn-primary {
  background-color: #180161;
  border: none;
  color: white;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 5px;
  font-weight: 700;
  transition: background-color 0.3s, border-color 0.3s;
  white-space: nowrap;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004494;
}

.btn-secondary {
  background: #180161;
  color: white;
  border: none;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 700;
  transition: background-color 0.3s;
  white-space: nowrap;
  height: 35px;
}

.titletext {
  padding-top: 200px;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.carousel-container {
  margin-top: 20px;
  width: 80%; /* Adjust width to make the carousel smaller */
  margin-left: auto;
  margin-right: auto;
}

.carousel .slide img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .carousel-container {
    width: 80%; /* Adjust width for smaller screens */
    margin-top: -40px;
  }
}

@media (max-width: 410px) {
  .carousel-container {
    width: 90%; /* Adjust width for very small screens */
    margin-bottom: 20px;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #f4f4f4;
  text-align: left;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.tablehead {
  background-color: #ffff;
  margin-top: 20px;
  border-radius: 10px;
}

h4 {
  font-size: 18px;
}

.input-button-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.form-control {
  flex: 1;
}

.btn-primary {
  flex: 0;
  white-space: nowrap;
}

.react-progress-bar {
  position: relative;
}

.react-progress-bar .label {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

.progress-bar-label {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 0 5px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

@media (max-width: 768px) {
  .react-progress-bar .label {
    font-size: 12px;
  }

  .input-button-row {
    flex-direction: row;
    gap: 10px;
  }

  .form-control {
    width: 100%;
  }

  .titletextOUTERHome {
    font-size: 1.5rem;
    transform: translateY(-10px); /* Moves the text 10px upwards */
    bottom: 20px;
  }
}

@media (max-width: 410px) {
  .react-progress-bar .label {
    font-size: 10px;
  }

  .input-button-row {
    flex-direction: row;
    gap: 10px;
  }

  .form-control {
    width: 100%;
  }

  .titletextOUTERHome {
    font-size: 1.5rem;
    margin-left: 25px;
    margin-top: -20px; /* Moves the text 20px upwards on very small screens */
  }
}
@media (min-width: 1080px) {
  .carousel-container {
    width: 80%;
    max-width: 1200px; /* Ensure it doesn’t exceed a reasonable width */
  }
}
@media (min-width: 1080px) {
  .titletextOUTERHome {
    font-size: 2rem;
    /* margin-top: 40px; */
  }
}
@media (min-width: 1080px) {
  .btn-primary {
    font-size: 18px;
    padding: 12px 30px;
  }
}
@media (min-width: 1080px) {
  .table {
    width: 100%; /* Ensure full width on larger screens */
  }
}
@media (min-width: 1080px) {
  .input-button-row {
    gap: 20px;
  }
}
.mt-5px {
  margin-top: 30px;
  margin-bottom: 10px;
}
.move-up{
  margin-top: -20px;
}