.main {
  min-height: 110vh;
  padding-top: 120px;
  background-image: url("../../Assets/Backgroiundimg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  margin-top: -40px;
}

.login form {
  margin: 20px;
  width: 700px;
  border-radius: 5px;
  padding: 20px 40px;
  color: white;
}

.login form img {
  width: 4000px;
  height: 250px;
}

.login form h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.login form .height {
  height: 45px;
}

.login form .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login form .logo img {
  width: 200px;
  height: 200px;
}

.login form .log-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

.login form .log-inputs .password {
  display: flex;
  flex-direction: column;
  position: relative;
}

.login form .log-inputs .password .forgot {
  display: flex;
  justify-content: flex-end;
}

.login form .log-inputs .password .openeyes,
.login form .log-inputs .password .closedeyes {
  position: absolute;
  right: 10px;
  top: 43px;
}

.login form .log-inputs .password a {
  color: inherit;
}

.login form .log-inputs .password a:hover {
  color: blue;
}

.login form a {
  text-decoration: none;
}

.login form h6 {
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 30px;
}

.login form .Enter {
  font-size: 20px;
}

.login form button {
  display: flex;
  width: 100%;
  margin-top: 10px;
  background-color: #180161;
  border-radius: 10px;
  height: 45px;
  font-size: 22px;
  font-weight: 600;
  align-items: center;
  color: white;
  justify-content: center;
  border: none;
}

#now {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: white;
}
/* #email{
  width: 100% !important;
} */

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titletext {
  position: relative;
  font-weight: 700;
  font-family: "Times New Roman", Times, serif;
  white-space: nowrap;
  top: -185px;
  margin-top: 10px;
}
.move-up{
  margin-top: -20px;
}