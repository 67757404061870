.register {
    display: flex;
    align-items: center;
    justify-content: center;
    form {
      margin: 20px;
      width: 90%; /* Use a percentage to make the form responsive */
      max-width: 900px; /* Limit the max width for larger screens */
      background: #ffffff33;
      border-radius: 5px;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .form-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
  
        .header-details {
          h4 {
            text-align: center;
            font-size: 16px; /* Adjust font size for better readability on smaller screens */
          }
        }
  
        img {
          width: 20vw; /* Use viewport width to make images responsive */
          max-width: 150px; /* Limit the max width */
          height: auto; /* Maintain aspect ratio */
          object-fit: contain; /* Ensure the image is contained within the bounds */
        }
      }
  
      .forminput-sec {
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        justify-content: center;
  
        .form-control {
          background: none;
          outline: none;
        }
      }
  
      button {
        display: flex;
        width: 100%;
        margin-top: 20px;
        background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1e50cf 100%);
        border-radius: 40px;
        height: 65px;
        align-items: center;
        justify-content: center;
        border: none;
      }
  
      .password {
        display: flex;
        position: relative;
        z-index: 0 !important;
  
        .openeye {
          position: absolute;
          right: 10px;
          top: 10px;
        }
  
        .closedeye {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }
  }
  
  @media (max-width: 920px) {
    .header-details {
      h4 {
        font-size: 14px; /* Further reduce font size for medium screens */
        text-align: center;
      }
    }
  }
  
  @media (max-width: 710px) {
    form {
      width: 100% !important;
      padding: unset !important;
    }
  }
  
  @media (max-width: 550px) {
    form {
      .forminput-sec {
        flex-direction: column !important;
        align-items: unset !important;
        justify-content: unset !important;
        gap: 5px !important;
      }
    }
  }
  
  @media (max-width: 400px) {
    form {
      .form-header {
        img {
          width: 30vw; /* Use a flexible size that adjusts on very small screens */
          max-width: 100px; /* Ensure it doesn't get too large */
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
  