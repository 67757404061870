/* Styling for the main outer container */
.mainouter {
  min-height: 100vh;
  background-color: #ffffff;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 20px solid;
  border-image-source: linear-gradient(67deg, #fdd835, #f57c00);
  border-image-slice: 1;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* For smaller screens, you can adjust the padding if needed */
@media (max-width: 768px) {
  .mainouter {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.logostyle {
  margin-bottom: 0px;
}

/* Styling for the title text */
.titletextOUTERmain {
  font-weight: 700;
  font-family: "Times New Roman", Times, serif;
  white-space: nowrap;
  text-align: center;
  font-size: 2.5rem;
}

/* General styling for the PDF icon */
.pdf-icon {
  width: 100px;
  height: auto;
}

.circle-text {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: black;
  text-align: center;
}

.buttonstyles {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.description {
  color: rgb(5, 5, 5);
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  padding: 0 10px;
  text-align: justify;
}

.stepsinsurance {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

@media (max-width: 768px) {
  .stepsinsurance {
    text-align: center;
    color: #f52900;
  }
}

@media (max-width: 430px) {
  .titletextOUTERmain {
    font-size: 1.3rem;
    margin-bottom: 40px;
  }

  .buttonstyles {
    justify-content: center;
    margin-top: -100px;
  }

  .vdostyler {
    margin-top: -40px;
  }

  .leveling {
    margin-top: 0px !important;
  }

  .stepsinsurance {
    font-size: 1.3rem;
    text-align: center;
    color: #f52900;
  }
}

/* Video container styling */
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  background: #000;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Additional responsive styling */
@media (max-width: 768px) {
  .vidiomaindiv {
    margin-top: 20px !important;
  }

  .leveling {
    margin-top: 30px;
  }
}

@media (max-width: 430px) {
  .vidiomaindiv {
    margin-top: 10px !important;
  }

  .leveling {
    margin-top: -10px;
  }

  .buttonstyles {
    margin-top: 10px;
  }
}

.mainouter img {
  width: 300px;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .mainouter img {
    width: 50px;
  }
}

@media (max-width: 430px) {
  .mainouter img {
    width: 200px;
  }
}

.downstyle {
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.pdficondiv {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  gap: 20px; /* Add space between the icons */
  padding: 0 20px; /* Add some padding to the sides */
  margin-top: -20px;
}

/* Pointer image styling */
.pointer-img {
  width: 100px;
  height: auto;
}

@media (max-width: 768px) {
  .pointer-img {
    width: 80px;
  }
}

@media (max-width: 430px) {
  .pointer-img {
    width: 60px;
  }
}

@media (min-height: 1000px) {
  .titletextOUTERmain {
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media (max-height: 1000px) {
  .titletextOUTERmain {
    font-size: 1.3rem;
    margin-top: 8px;
    margin-bottom: 5px;
  }
}

@media (max-height: 600px) {
  .titletextOUTERmain {
    font-size: 1.3rem;
    margin-top: 4px;
  }
}
.move-up {
  margin-top: -20px;
}
.move-up1{
  margin-top: -10px;
}
.move{
  margin-left: 25px;
  margin-top: -25px;

}
.moves{
  margin-right: 25px;
  margin-top: -25px;
}